<template>
  <div
    class="g-gantt-chart"
    :style="{ width, height, background: themeColors.background }"
  >
    <g-gantt-timeaxis
      v-if="!hideTimeaxis"
      :chart-start="chartStart"
      :chart-end="chartEnd"
      :row-label-width="rowLabelWidth"
      :timemarker-offset="timemarkerOffset"
      :theme-colors="themeColors"
      :locale="locale"
      :precision="precision"
      :time-format="timeFormat"
      :time-count="timeCount"
      :grid-size="gridSize"
    />

    <div
      class="g-gantt-rows-container"
      :style="{ width: `${timeCount * gridSize + rowLabelWidth}px` }"
    >
      <div
        v-if="isShowOverlay"
        style="
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          background-color: rgba(50, 50, 50, 0.05);
          position: absolute;
          z-index: 999;
        "
      />
      <g-gantt-grid
        v-if="grid"
        :chart-start="chartStart"
        :chart-end="chartEnd"
        :row-label-width="rowLabelWidth"
        :highlighted-hours="highlightedHours"
        :highlighted-days="highlightedDays"
        :precision="precision"
        :time-count="timeCount"
        :grid-size="gridSize"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import { getDateToUnix, nowUnix } from '@/core'
import GGanttBar from './GGanttBar.vue'
import GGanttGrid from './GGanttGrid.vue'
import GGanttRow from './GGanttRow.vue'
import GGanttTimeaxis from './GGanttTimeaxis.vue'
import GanttasticThemeColors from './GanttasticThemeColors.js'
import moment from '@/core/date'

export default {
  name: 'GGanttChart',

  components: {
    GGanttTimeaxis,
    GGanttGrid,
  },

  // all child components of GGanttChart may have access to
  // the following values by using Vue's "inject" option:
  provide() {
    return {
      getChartStart: () => this.chartStart,
      getChartEnd: () => this.chartEnd,
      getTimeCount: () => this.timeCount,
      ganttChartProps: this.$props,
      getThemeColors: () => this.themeColors,
      initDragOfBarsFromBundle: (bundleId, e) =>
        this.initDragOfBarsFromBundle(bundleId, e),
      moveBarsFromBundleOfPushedBar: (bar, minuteDiff, overlapType) =>
        this.moveBarsFromBundleOfPushedBar(bar, minuteDiff, overlapType),
      setDragLimitsOfGanttBar: ganttBar =>
        this.setDragLimitsOfGanttBar(ganttBar),
      onBarEvent: (e, ganttBar) => this.onBarEvent(e, ganttBar),
      onDragendBar: (e, ganttBar, action) =>
        this.onDragendBar(e, ganttBar, action),
      shouldSnapBackOnOverlap: () => this.snapBackOnOverlap,
      snapBackBundle: ganttBar => this.snapBackBundle(ganttBar),
      getMinGapBetweenBars: () => this.minGapBetweenBars,
      getDefaultBarLength: () => this.defaultBarLength,
      getTimeUnit: () => this.timeUnit,
      getTimeFormat: () => this.timeFormat,
    }
  },

  props: {
    chartStart: { type: String, required: true },
    chartEnd: { type: String, required: true },
    hideTimeaxis: { type: Boolean, default: false },
    rowLabelWidth: { type: Number, default: 200 },
    rowHeight: { type: Number, default: 40 },
    locale: { type: String, default: 'ru' },
    theme: { type: String },
    grid: { type: Boolean, default: false },
    gridSize: { type: Number, default: 30 },
    highlightedHours: { type: Array, default: () => [] },
    highlightedDays: { type: Array, default: () => [] }, // format YYYY-MM-DD
    width: { type: String, default: '100%' }, // the total width of the entire ganttastic component in %
    height: { type: String, default: '100%' },
    pushOnOverlap: { type: Boolean },
    isMagnetic: { type: Boolean },
    snapBackOnOverlap: { type: Boolean },
    minGapBetweenBars: { type: Number, default: 0 },
    defaultBarLength: { type: Number, default: 1 },
    precision: { type: String, default: 'month' }, // 'month', 'day'
    barStartKey: { type: String, default: 'start' }, // property name of the bar objects that represents the start datetime
    barEndKey: { type: String, default: 'end' }, // property name of the bar objects that represents the end datetime
    mayAdd: { type: Boolean, default: true },
    isShowOverlay: { type: Boolean, default: false },
  },

  data() {
    return {
      timemarkerOffset: 0,
      movedBarsInDrag: new Set(),
    }
  },

  computed: {
    timeUnit() {
      return this.precision === 'month' ? 'days' : 'hours'
    },

    timeFormat() {
      return this.precision === 'month' ? 'YYYY-MM-DD HH' : 'YYYY-MM-DD HH:mm'
    },

    timeCount() {
      let momentChartStart = moment(this.chartStart)
      let momentChartEnd = moment(this.chartEnd)

      return Math.floor(
        momentChartEnd.diff(momentChartStart, this.timeUnit, true),
      )
    },

    themeColors() {
      return GanttasticThemeColors[this.theme] || GanttasticThemeColors.default
    },
  },

  methods: {
    getGanttBarChildrenList() {
      let ganttBarChildren = []
      let ganttRowChildrenList = this.$children.filter(
        childComp => childComp.$options.name === GGanttRow.name,
      )

      ganttRowChildrenList.forEach(row => {
        let ganttBarChildrenOfRow = row.$children.filter(
          childComp => childComp.$options.name === GGanttBar.name,
        )

        ganttBarChildren.push(...ganttBarChildrenOfRow)
      })

      return ganttBarChildren
    },

    getBarsFromBundle(bundleId) {
      if (bundleId === undefined || bundleId === null) {
        return []
      }

      return this.getGanttBarChildrenList().filter(
        ganttBarChild => ganttBarChild.barConfig.bundle === bundleId,
      )
    },

    initDragOfBarsFromBundle(gGanttBar, e) {
      gGanttBar.initDrag(e)
      this.movedBarsInDrag.add(gGanttBar.bar)

      if (
        gGanttBar.barConfig.bundle !== null &&
        gGanttBar.barConfig.bundle !== undefined
      ) {
        this.getGanttBarChildrenList().forEach(ganttBarChild => {
          if (
            ganttBarChild.barConfig.bundle === gGanttBar.barConfig.bundle &&
            ganttBarChild !== gGanttBar
          ) {
            ganttBarChild.initDrag(e)
            this.movedBarsInDrag.add(ganttBarChild.bar)
          }
        })
      }
    },

    moveBarsFromBundleOfPushedBar(pushedBar, minuteDiff, overlapType) {
      this.movedBarsInDrag.add(pushedBar)
      let bundleId = pushedBar.ganttBarConfig?.bundle

      if (bundleId === undefined || bundleId === null) {
        return
      }
      this.getGanttBarChildrenList().forEach(ganttBarChild => {
        if (
          ganttBarChild.barConfig.bundle === bundleId &&
          ganttBarChild.bar !== pushedBar
        ) {
          ganttBarChild.moveBarByChildPointsAndPush(minuteDiff, overlapType)
          this.movedBarsInDrag.add(ganttBarChild.bar)
        }
      })
    },

    shouldSnapBackBar(ganttBar) {
      if (
        this.snapBackOnOverlap &&
        ganttBar.barConfig.pushOnOverlap !== false
      ) {
        let { overlapBar } = ganttBar.getOverlapBarAndType(ganttBar.bar)

        return Boolean(overlapBar)
      }

      return false
    },

    snapBackBundleIfNeeded(ganttBar) {
      let barsFromBundle = this.getBarsFromBundle(ganttBar.barConfig.bundle)

      if (
        this.shouldSnapBackBar(ganttBar) ||
        barsFromBundle.some(gBar => this.shouldSnapBackBar(gBar))
      ) {
        ganttBar.snapBack()
        barsFromBundle.forEach(gBar => gBar.snapBack())

        return true
      }

      return false
    },

    onBarEvent({ event, type, time, rowId }, ganttBar) {
      this.$emit(`${type}-bar`, { event, bar: ganttBar.bar, time, rowId })
    },

    onDragendBar(e, ganttBar, action) {
      let didSnapBack = this.snapBackBundleIfNeeded(ganttBar)
      // movedBars - бары которые двигаються совместно
      let movedBars = didSnapBack ? new Set() : this.movedBarsInDrag

      // Magnetic suction
      if (movedBars.size && this.isMagnetic) {
        let { left, right /* , move*/ } = action

        movedBars.forEach(bar => {
          if (this.precision === 'month') {
            // eslint-disable-next-line eqeqeq
            if (left && bar == ganttBar.bar) {
              if (moment(bar[this.barStartKey]).hours() < 12) {
                bar[this.barStartKey] = moment(bar[this.barStartKey])
                  .hours(0)
                  .format()
              } else {
                bar[this.barStartKey] = moment(bar[this.barStartKey])
                  .hours(24)
                  .format()
              }
              // eslint-disable-next-line eqeqeq
            } else if (right && bar == ganttBar.bar) {
              if (moment(bar[this.barEndKey]).hours() < 12) {
                bar[this.barEndKey] = moment(bar[this.barEndKey])
                  .hours(0)
                  .format()
              } else {
                bar[this.barEndKey] = moment(bar[this.barEndKey])
                  .hours(24)
                  .format()
              }
            } else if (moment(bar[this.barStartKey]).hours() < 12) {
              bar[this.barStartKey] = moment(bar[this.barStartKey])
                .hours(0)
                .format()
              bar[this.barEndKey] = moment(bar[this.barEndKey])
                .hours(0)
                .format()
            } else {
              bar[this.barStartKey] = moment(bar[this.barStartKey])
                .hours(24)
                .format()
              bar[this.barEndKey] = moment(bar[this.barEndKey])
                .hours(24)
                .format()
            }
            // eslint-disable-next-line eqeqeq
          } else if (left && bar == ganttBar.bar) {
            if (moment(bar[this.barStartKey]).minutes() < 30) {
              bar[this.barStartKey] = moment(bar[this.barStartKey])
                .minutes(0)
                .format()
            } else {
              bar[this.barStartKey] = moment(bar[this.barStartKey])
                .minutes(60)
                .format()
            }
          }
          // eslint-disable-next-line eqeqeq
          else if (right && bar == ganttBar.bar) {
            if (moment(bar[this.barEndKey]).minutes() < 30) {
              bar[this.barEndKey] = moment(bar[this.barEndKey])
                .minutes(0)
                .format()
            } else {
              bar[this.barEndKey] = moment(bar[this.barEndKey])
                .minutes(60)
                .format()
            }
          } else if (moment(bar[this.barStartKey]).minutes() < 30) {
            bar[this.barStartKey] = moment(bar[this.barStartKey])
              .minutes(0)
              .format()
            bar[this.barEndKey] = moment(bar[this.barEndKey])
              .minutes(0)
              .format()
          } else {
            bar[this.barStartKey] = moment(bar[this.barStartKey])
              .minutes(60)
              .format()
            bar[this.barEndKey] = moment(bar[this.barEndKey])
              .minutes(60)
              .format()
          }
        })
      }
      this.movedBarsInDrag = new Set()
      this.$emit('dragend-bar', { event: e, bar: ganttBar.bar, movedBars })
    },

    // ------------------------------------------------------------------------
    // --------  METHODS FOR SETTING THE DRAG LIMIT OF A BAR   ----------------
    // ------------------------------------------------------------------------

    // how far you can drag a bar depends on the position of the closest immobile bar
    // note that if a bar from the same row belongs to a bundle
    // other rows might need to be taken into consideration, too
    setDragLimitsOfGanttBar(bar) {
      if (!this.pushOnOverlap || bar.barConfig.pushOnOverlap === false) {
        return
      }
      for (let side of ['left', 'right']) {
        let [totalGapDistance, bundleBarsOnPath] =
          this.countGapDistanceToNextImmobileBar(bar, null, side, false)

        for (let i = 0; i < bundleBarsOnPath.length; i++) {
          let barFromBundle = bundleBarsOnPath[i].bar
          let gapDist = bundleBarsOnPath[i].gapDistance
          let otherBarsFromBundle = this.getBarsFromBundle(
            barFromBundle?.barConfig?.bundle,
          ).filter(otherBar => otherBar !== barFromBundle)

          otherBarsFromBundle.forEach(otherBar => {
            let [newGapDistance, newBundleBars] =
              this.countGapDistanceToNextImmobileBar(otherBar, gapDist, side)

            if (
              newGapDistance !== null &&
              (newGapDistance < totalGapDistance || !totalGapDistance)
            ) {
              totalGapDistance = newGapDistance
            }
            newBundleBars.forEach(newBundleBar => {
              if (
                !bundleBarsOnPath.find(
                  barAndGap => barAndGap.bar === newBundleBar.bar,
                )
              ) {
                bundleBarsOnPath.push(newBundleBar)
              }
            })
          })
        }

        if (totalGapDistance != null && side === 'left') {
          bar.dragLimitLeft =
            bar.$refs['g-gantt-bar'].offsetLeft - totalGapDistance
        } else if (totalGapDistance != null && side === 'right') {
          bar.dragLimitRight =
            bar.$refs['g-gantt-bar'].offsetLeft +
            bar.$refs['g-gantt-bar'].offsetWidth +
            totalGapDistance
        }
      }
      // all bars from the bundle of the clicked bar need to have the same drag limit:
      let barsFromBundleOfClickedBar = this.getBarsFromBundle(
        bar.barConfig.bundle,
      )

      barsFromBundleOfClickedBar.forEach(barFromBundle => {
        barFromBundle.dragLimitLeft = bar.dragLimitLeft
        barFromBundle.dragLimitRight = bar.dragLimitRight
      })
    },

    // returns the gap distance to the next immobile bar
    // in the row where the given bar (parameter) is (added to gapDistanceSoFar)
    // and a list of all bars on that path that belong to a bundle
    countGapDistanceToNextImmobileBar(
      bar,
      gapDistanceSoFar,
      side = 'left',
      ignoreShadows = true,
    ) {
      let bundleBarsAndGapDist = bar.barConfig.bundle
        ? [{ bar, gapDistance: gapDistanceSoFar }]
        : []
      let currentBar = bar
      let nextBar = this.getNextGanttBar(currentBar, side)
      // расчитываем длину од толкаемого бара до начала активного часа
      let offsetMovingBarsToLeft = this.getActiveHourDistance(bar)

      // left side:
      if (side === 'left') {
        while (nextBar) {
          let nextBarOffsetRight =
            nextBar.$refs['g-gantt-bar'].offsetLeft +
            nextBar.$refs['g-gantt-bar'].offsetWidth

          gapDistanceSoFar +=
            currentBar.$refs['g-gantt-bar'].offsetLeft - nextBarOffsetRight

          offsetMovingBarsToLeft -= nextBar.$refs['g-gantt-bar'].offsetWidth + 2
          // если есть бар между активным часом и толкаемым баром вычитаем длину этого бара
          // 2 - поправка чтоб не заезжал слишком далеко за линию активного часа

          if (
            getDateToUnix(nextBar.bar.time_from) <= nowUnix() ||
            (nextBar.barConfig.isShadow && !ignoreShadows)
          ) {
            return [gapDistanceSoFar, bundleBarsAndGapDist]
          }
          if (nextBar.barConfig.bundle) {
            bundleBarsAndGapDist.push({
              bar: nextBar,
              gapDistance: gapDistanceSoFar,
            })
          }
          currentBar = nextBar
          nextBar = this.getNextGanttBar(nextBar, 'left')
        }

        if (getDateToUnix(bar.bar.time_from) < nowUnix()) {
          return [this.getActiveHourDistance(bar), bundleBarsAndGapDist]
        }

        // отдаем растояние от толкаемого бара до активного часа с учетом других баров
        return [offsetMovingBarsToLeft, bundleBarsAndGapDist]
      }
      if (side === 'right') {
        while (nextBar) {
          let currentBarOffsetRight =
            currentBar.$refs['g-gantt-bar'].offsetLeft +
            currentBar.$refs['g-gantt-bar'].offsetWidth

          gapDistanceSoFar +=
            nextBar.$refs['g-gantt-bar'].offsetLeft - currentBarOffsetRight

          if (
            nextBar.barConfig.immobile ||
            (nextBar.barConfig.isShadow && !ignoreShadows)
          ) {
            return [gapDistanceSoFar, bundleBarsAndGapDist]
          }
          if (nextBar.barConfig.bundle) {
            bundleBarsAndGapDist.push({
              bar: nextBar,
              gapDistance: gapDistanceSoFar,
            })
          }
          currentBar = nextBar
          nextBar = this.getNextGanttBar(nextBar, 'right')
        }
      }

      return [gapDistanceSoFar, bundleBarsAndGapDist]
    },

    getNextGanttBar(bar, side = 'left') {
      let allBarsLeftOrRight = []

      if (side === 'left') {
        allBarsLeftOrRight = bar.$parent.$children.filter(
          gBar =>
            gBar.$options.name === GGanttBar.name &&
            gBar.$parent === bar.$parent &&
            gBar.$refs['g-gantt-bar'] &&
            gBar.$refs['g-gantt-bar'].offsetLeft <
              bar.$refs['g-gantt-bar'].offsetLeft &&
            gBar?.barConfig?.pushOnOverlap !== false,
        )
      } else {
        allBarsLeftOrRight = bar.$parent.$children.filter(
          gBar =>
            gBar.$options.name === GGanttBar.name &&
            gBar.$parent === bar.$parent &&
            gBar.$refs['g-gantt-bar'] &&
            gBar.$refs['g-gantt-bar'].offsetLeft >
              bar.$refs['g-gantt-bar'].offsetLeft &&
            gBar.barConfig.pushOnOverlap !== false,
        )
      }
      if (allBarsLeftOrRight.length > 0) {
        return allBarsLeftOrRight.reduce((bar1, bar2) => {
          let bar1Dist = Math.abs(
            bar1.$refs['g-gantt-bar'].offsetLeft -
              bar.$refs['g-gantt-bar'].offsetLeft,
          )
          let bar2Dist = Math.abs(
            bar2.$refs['g-gantt-bar'].offsetLeft -
              bar.$refs['g-gantt-bar'].offsetLeft,
          )

          return bar1Dist < bar2Dist ? bar1 : bar2
        }, allBarsLeftOrRight[0])
      }

      return null
    },

    getActiveHourDistance(bar) {
      const barOffsetLeft = bar.$refs['g-gantt-bar'].offsetLeft
      const activeHour = this.$children.find(
        child => 'activeHour' in child.$refs,
      ).$refs.activeHour[0]

      const activeHourOffsetLeft = activeHour.offsetLeft

      return barOffsetLeft - activeHourOffsetLeft < 0
        ? 0
        : barOffsetLeft - activeHourOffsetLeft
    },

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
  },
}
</script>
