var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "g-gantt-chart",
      style: {
        width: _vm.width,
        height: _vm.height,
        background: _vm.themeColors.background,
      },
    },
    [
      !_vm.hideTimeaxis
        ? _c("g-gantt-timeaxis", {
            attrs: {
              "chart-start": _vm.chartStart,
              "chart-end": _vm.chartEnd,
              "row-label-width": _vm.rowLabelWidth,
              "timemarker-offset": _vm.timemarkerOffset,
              "theme-colors": _vm.themeColors,
              locale: _vm.locale,
              precision: _vm.precision,
              "time-format": _vm.timeFormat,
              "time-count": _vm.timeCount,
              "grid-size": _vm.gridSize,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "g-gantt-rows-container",
          style: {
            width: `${_vm.timeCount * _vm.gridSize + _vm.rowLabelWidth}px`,
          },
        },
        [
          _vm.isShowOverlay
            ? _c("div", {
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  left: "0",
                  right: "0",
                  "background-color": "rgba(50, 50, 50, 0.05)",
                  position: "absolute",
                  "z-index": "999",
                },
              })
            : _vm._e(),
          _vm.grid
            ? _c("g-gantt-grid", {
                attrs: {
                  "chart-start": _vm.chartStart,
                  "chart-end": _vm.chartEnd,
                  "row-label-width": _vm.rowLabelWidth,
                  "highlighted-hours": _vm.highlightedHours,
                  "highlighted-days": _vm.highlightedDays,
                  precision: _vm.precision,
                  "time-count": _vm.timeCount,
                  "grid-size": _vm.gridSize,
                },
              })
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }